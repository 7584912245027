@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?5u1lcn');
  src:  url('fonts/icomoon.eot?5u1lcn#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?5u1lcn') format('truetype'),
    url('fonts/icomoon.woff?5u1lcn') format('woff'),
    url('fonts/icomoon.svg?5u1lcn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-camera:before {
  content: "\e900";
}
.icon-cloud-rain:before {
  content: "\e901";
}
.icon-globe:before {
  content: "\e902";
}
.icon-headphones:before {
  content: "\e903";
}
.icon-image:before {
  content: "\e904";
}
.icon-message-square:before {
  content: "\e905";
}
.icon-sliders:before {
  content: "\e906";
}
.icon-unlock:before {
  content: "\e907";
}
.icon-mouse:before {
  content: "\e323";
}
/* icons-above */
.AppWrapper{ 
  margin-left: 15rem 
}
.siteHeader{
  transition: all .5s;
}
.siteHeader ul{ padding: 30px 25px 0 }
.siteHeader nav a{
  color:#666;
  border-bottom:1px solid #efefef;
  padding-bottom:10px !important;
  margin-bottom:10px !important;
  display: block;
  font-size: 17px !important
}
.siteHeader nav a:hover{ text-decoration: none }
.siteHeader nav a.active, .siteHeader nav a:hover{
  color:#00a1d9;
  border-color:#00a1d9
}

.siteNavigation a{ 
  color: #777;
  display: block;
  padding-bottom: 8px
}
.siteNavigation a:hover{ color:#00a1d9 }
.corceptualLogo{
  width: 130px;
  text-align: center;
  margin-left: 55px;
  padding-top:10px
}

.corceptualLogo img{
  width:100%
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.siteHeader{
  position:fixed;
  left:0;
  height:100vh;
  z-index: 2;
  width:15rem;
  background-color:#fbfbfb;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  overflow: auto

}
.mainSection{
  padding:20px 30px 70px
}
.home-banner{  
  display: flex;
  position: relative; 
  background-color: #23a0d9; 
  margin-top: 10px;
  align-items: center;
  justify-content: space-around
}
.banner-left{
  line-height:0
}
.bg-section{
  background: url("./img/bg-desktop.png") no-repeat 0 0;
  background-size: 100% 100%, cover;
  padding: 200px 0
}
.home-banner{
  padding: 140px 40px 150px;
}

.banner-right h2{
  color:#fff; 
  text-transform: uppercase; 
  font-weight: 700
}
.tag-line{
  position: absolute;
  bottom: 0;
  right:40px;
  font-size: 22px;
  font-weight: 700;
  color:#fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 32px;
}
.tag-line span:first-child{ color:#aaa }
.s-company{
  padding-left:18px; 
  letter-spacing: 2.5px;
  font-weight: 700
}
.banner-left{
  /* width:50%; */
  background-color: #e8e8e6
}
.banner-left img{
  width: 100%;
  border-radius: 22px
}
.banner-right{
  width: 40%;
  text-align: center;
  padding: 10px 0;
  color: #fff;
}
.banner-right li{
  padding-bottom: 8px
}
.banner-right p{
  color: #fff
}
.banner-right img{
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  margin: 10px 0 20px;
  width:50%
}
.pageHead{
  position: relative;
  padding-left: 10px
}
.pageHead:before{
  content: "";
  background-color: #00a1d9;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: absolute;
  opacity: .3;
  top: -2px;
  left: 0
}
.pageHead span a{ color:#666; text-decoration: underline}
.gooDay {
  text-align: center;
}
.gooDay h2{
  font-size: 48px;
  color:#00a1d9;
  font-weight: bold
}
.thanksMsg{ color : #00a1d9}
.contactForm{ text-align: center; margin: 30px 0 50px}
.mainSection{position: relative}
.mainSection h2{ text-align: center }
.mainSection h2 span{ 
  font-size: 35px; 
  color:#23a1d9; 
  display: inline-block;
  border-bottom:1px solid #ccc;
  padding: 10px;
  text-transform: uppercase;
  margin: 30px 0 20px
}
.service-glimse{ 
  margin: 0 0 50px;
  text-align: center;
  padding-top: 30px;
  box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.1)
}
.service-glimse li{
  position: relative;
  display: inline-block;
  width:205px;
  text-align: center;
  height:210px;
  margin: 0 15px 30px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px
}
.service-glimse h3{
  font-size: 70px !important;
  color:#fff;
  padding: 50px 0 30px 0 !important;
  border-bottom:2px solid #fff
}
.service-glimse div{
  position: absolute;
  bottom:20px;
  width:100%;
  color:#444;
  font-weight: 500
}
.service-glimse li:first-child{
  background-color: #f9a19a
}
.service-glimse li:nth-child(2){
  background-color: #c5cae9
}
.service-glimse li:nth-child(3){
  background-color: #c8e6c9
}
.service-glimse li:nth-child(4){
  background-color: #ffccbc
}
.service-glimse li:nth-child(5){
  background-color: #b3e5fc
}
.service-glimse li:nth-child(6){
  background-color: #ffcc80
}
.service-glimse li:nth-child(7){
  background-color: #80deea
}
.service-glimse li:nth-child(8){
  background-color: #d7ccc8
}
.home-strategy {
  padding: 50px 0 50px 80px;
  position: relative;
  min-height: 250px;
  margin-left: 50%;
  border-left:2px dashed #ccc;

}
.home-strategy:before{
  content:" ";
  width:305px;
  height:305px;
  border-radius: 50%;
  position: absolute;
  border:50px solid #00a1d9;
  top:25px;
  left: -400px;
  box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.5);
  background: url(img/ideas.jpg) no-repeat center;
  background-color: #e9ecf1
}
.home-strategy:after{
  content:" ";
  width:20px;
  height:20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute
}
.our-strenght{
  font-weight: 300;
  line-height: 28px;
  padding-left: 20px
}
.home-about:after{
  content:" ";
  top:0;
  border: dashed 1px solid; 
  border-left: 140px solid transparent;
	border-right: 140px solid transparent;
	border-bottom: 250px solid red;
  position: absolute;
  right: 0
}
.webIcon, .appleIcon, .androidIcon{
  margin-top: 10px;
  display: inline-block;
  padding-top: 40px;
  text-align: center;
  width: 60px;
  background-position-x: center !important
}
.webIcon      { background: url('./img/sphere.png')   no-repeat }
.appleIcon    { background: url('./img/apple.png')    no-repeat }
.androidIcon  { background: url("./img/android.png")  no-repeat }

.brand-color{ color:#00a1d9 }
.color-white{ color: #fff !important }
.fWeight{ font-weight: 500 }
.about-us{text-align: center}
.subHead{
  color:#00a1d9;
  font-size: 80px;
  font-weight: 700
}
.subHead-long{
  color:#00a1d9;
  font-size: 40px;
  font-weight: 700
}
.about-us span{
  padding:0 10px;
  color:#666;
  text-transform: uppercase;
  font-weight: 300;
  border-right:1px solid #00a1d9
}
.about-us span:last-child{ border-right:none }
.start-up{
  margin: 70px 0 0 30px;
  padding-left: 430px;
  /* max-width: 500px; */
  background: url(./img/end-to-end.png) no-repeat top left;
  min-height: 250px;
  color:#666;
  font-weight: 300
}
.start-up p:first-child{
  padding-top: 35px;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 2px
}
.start-up p:last-child{
  padding-top: 10px;
  font-size: 22px;
  letter-spacing: 2px;
  line-height: 25px;
  color: #999
}
.more-about-us{
  margin: 90px 0 20px;
  padding: 50px;
  background-color: #00a1d9;
  color:#fff;
  position: relative
}
.more-about-us:after{
  content:" ";
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  border-left: 30px solid transparent;
	border-right: 30px solid transparent;
  border-top: 30px solid #fff;
  margin: 0 auto;
  width: 0
}
.more-about-us:before{
  content: " ";
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid #00a1d9;
  z-index: 1;
  margin: 0 auto;
  width: 0px;
}
.more-about-us p{
  line-height: 22px
}
.more-about-us div{
  font-size: 22px
}
.text-center{ text-align: center}
.coffee-holder {
  background-color:#fff;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 170px;
  height: 160px;
  margin: 50px 20px 0 0
}
.meetat-coffee a{
  color:#fff;
  text-decoration: underline
}
.coffee-holder img{ 
  position: relative; 
  width: 150px; 
  left:3px; 
  /* top:0; */
  vertical-align: middle;
}
.siteFooter{
  position: relative;
  text-align: left;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #777;
  background-color: #fbfbfb;
  box-shadow: 2px 0 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding : 1.5rem 1rem;
  box-sizing: border-box;
  z-index: 2;
}
.socialMedia{
  text-align: right;
  position: absolute;
  right: 10px;
  bottom:10px
}
.socialMedia a{
  margin-left: 10px;
  opacity: .8
}
.socialMedia a:hover{
  opacity: 1
}
.siteHeader nav li { position:relative }
.new-item:after{
  content:"new";
  position:absolute;
  top: 0;
    right: 0;
    color: #fff;
    background-color: #2fa9dd;
    padding: 0 2px 2px;
    border-radius: 5px;
    font-weight: bold
}
.blockChainImg { 
  position:relative;
  text-align: center;
  margin: 20px 0 40px;
}
.chain{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 66px;
  height: 68px;
  /* opacity: 0.6 */
}
.chain-of-blocks {
  display:flex;
  margin: 10px auto 60px;
  width:80%;
  justify-content: space-between;
  background-color: #f2f2f2 
}

.chain-of-blocks li{
  padding: 0 0 10px;
  border:1px dashed #FFA500;
  flex-basis: 25%;
  border-top:0;
  font-size: 14px;
  border-right: none;
  color:#000
}
.chain-of-blocks li:last-child{
  border-right:1px dashed #FFA500;
}

.chain-of-blocks span{ 
  display: block; 
  background-color: #FFA500;
  color:#fff;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px
}
.alignCenter{
  text-align: center
}
.faqAnswers, .faqAnswersMulti{ padding: 5px 0 0 5px; font-weight: bold }
.faqAnswers{ margin-bottom:20px }
.withHelpText{ padding-bottom:8px} 
.blockChainUses{
  padding: 20px 0 0;
  display:flex;
  flex-wrap: wrap
}
.blockChainUses li img{ display: block; width:100%; padding-bottom: 20px}
.blockChainUses li { 
  padding:10px;
  flex-basis: 50%;
  color:#2fa9dd;
  box-sizing: border-box;
  /* background-color: #FFA500; */
}
.cbsSoftware li img{
  width:auto;
  display: inline-block;
  padding:0
}
.cbsSoftware li{ color: inherit }
.autoImageWrapper{
  border:1px solid #ccc;
  text-align: center;
  padding:30px 0;
  margin-bottom: 15px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  background: url("./img/cbs-bg.png") no-repeat right bottom
}
.cbsSoftware a{
  padding: 8px 0 5px;
  display: inline-block;
  color:#2fa9dd;
  border-bottom:2px dashed #2fa9dd
}
.link-button{
  padding: 8px 0 5px;
  display: inline-block;
  color: #2fa9dd;
  border: none;
  border-bottom: 2px dashed #2fa9dd;
  font-size: 16px;
  cursor: pointer
}
.cbsSoftware a:hover, .link-button:hover{
  border-bottom-style: solid
}
.blockChainUses li:first-child,  
.blockChainUses li:nth-child(2) { 
  margin-bottom: 40px
}
.blockChainUses {
  text-align: left
}
.blockChainUses h5{
  padding: 0 !important;
    color: #000;
    font-size: 18px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-top: 5px !important
}
.fontBold{
  font-size: 22px;
  padding: 10px;
  display: inline-block;
  border: 1px dashed #fff;
  margin: 20px 0 10px
}
#open-tray{ display:none }
.formLoader {
  position: absolute;
  top: 200px;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: -1;
  width: 200px
}
.solutions li{
  display: inline-block;
  border: 2px dashed #ddd;
  margin: 5px;
  padding: 5px;
}
.callSupport{
  padding:10px 0 0 25px
}
.callSupport a{
  color: #FFA500;
  padding-top:8px;
  display: inline-block;
  border-bottom:1px dashed #FFA500
}

.meetat-coffee code{ color:#fff }

/* job-card */
.job-title{
    padding: 0 !important;
    color:#000;
    font-size: 14px !important;
    font-weight: bold !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 8px !important
}
.job-company{
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 15px;
  color: #23a1d9
}
.job-exp, .job-location{
  font-weight: bold;
  font-size: 13px;
  display: inline-block;
  padding-left: 20px;
  line-height: 16px;
  position: relative
}
.job-exp{ margin-right: 20px}
.job-exp:before{
  content:"";
  position: absolute;
  left: 0;
  background: url('images/briefcase.png') no-repeat 0 0;
  background-size: 15px;
  width:20px; height: 20px;
  opacity: .5
}
.job-location:before{
  content:"";
  position: absolute;
  left: 0;
  background: url('images/location.png') no-repeat 0 0;
  background-size: 15px;
  width:20px; height: 20px;
  opacity: .5
}
.job-description{
  font-size: 13px;
  margin-top: 15px;
  line-height: 16px
}
.job-card{
  padding: 20px 0 0;
  display: flex;
  flex-wrap: wrap
}
.job-card li {    
  padding: 10px;
  flex-basis: 33.33%;
  /* color: #2fa9dd; */
  box-sizing: border-box
}
.job-card-wrap{
  border: 1px solid #ccc;
  padding: 30px 20px;
  /* margin-bottom: 30px; */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  min-height: 230px;
  max-height: 230px;
  overflow-y: auto;
  position: relative
}
.admin-card{
  min-height: 236px;
  max-height: 236px
}
.date-posted{
  margin-top: 10px;
  text-align: right;
  font-size: 12px;
  color: #999
}
.postedby{
  margin-top: 5px;
  text-align: right;
  font-size: 13px;
  color: #888
}
.colorDark{ color: #555 }
.colorOrange, .colorOrange > span{ color: orange }
.colorGreen, .colorGreen > span{ color: green }
.colorRed, .colorRed > span{ color: red }


.cancel-button{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px
}
.cancel-button:hover{
  border-color: #23a1d9;
  color: #23a1d9
}

.para{
  padding:0 40px 20px;
  line-height: 21px
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) { 
  .chain-of-blocks{ width:auto }
  .start-up {
    margin: 70px 0 0 30px;
    padding: 310px 0 0;
    min-height: auto;
    background-size: 100%
  }
  .meetat-coffee a {
    display: block;
    padding-top: 20px
  }
  .meetat-coffee{ text-align: center}
  .meetat-coffee code { font-size: 18px; padding-top: 40px; display: block }
  .service-glimse li { width: 200px }
  .about-us span {
    margin-bottom: 5px;
    display: inline-block
  }
  .job-card li { flex-basis: 50% }
 }
/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .AppWrapper{ margin-left:0 }
  .siteHeader { left:-15.2rem; overflow: visible }
  #open-tray{ 
    display:block;
    position: absolute;
    top:5px;
    right: -34px;
    width: 25px;
    /* border-radius: 4px; */
    /* padding: 2px 2px 4px; */
    border-bottom:2px solid #00a1d9;
    /* background-color: #00a1d9 */
  }
  #open-tray:after{
    content:"";
    display: block;
    padding: 5px 0;
    border-top:2px solid #00a1d9;
    /* border-bottom:2px solid #fff */
   }
  #open-tray:before{
    content:"";
    display: block;
    position: relative;
    top:8px;
    height: 2px;
    border-bottom:2px solid #00a1d9
    /* background-color:#fff */
  }
  .showTray{ left:0 }
  .showTray #open-tray { 
    background-color: #fbfbfb;
    right: -27px;
    box-shadow: 3px 1px 4px -1px rgba(0, 0, 0, 0.2);
    padding: 12px 0px 0 1px;
    height: 20px;
    border-radius: 2px;
    border-bottom: none;
    top: 6px;
    width: 27px
   }
  .showTray #open-tray:after{ 
    transform: rotate(45deg);
    padding: 0;
   }
  .showTray #open-tray:before{ 
    transform: rotate(-45deg);
    top: 2px;
    right: 1px
   }
   .home-banner { flex-wrap: wrap }
   .banner-right, .banner-left{ width:auto }
   .banner-right p { line-height: normal }

   .home-strategy {
    padding: 310px 0 0 20px;
    position: relative;
    /* min-height: 250px; */
    margin-left: 0;
    border-left: 2px dashed #ccc;
  }
  .home-strategy:before {
    top: 0;
    left: auto;
    opacity: .8;
  }
  .more-about-us { margin: 40px 0 0; padding: 40px 20px 20px }
  .siteFooter { text-align: center; position: static }
  .socialMedia { text-align: center; position: static; margin-top: 20px}
  .mainSection { padding-bottom: 50px }
  .subHead-long {
    font-size: 20px;
    font-weight: 500;
  }
  .chain-of-blocks { flex-wrap: wrap; background: none }
  .chain-of-blocks li { 
    flex-basis: 100%; 
    margin-bottom: 10px; 
    background-color: #f2f2f2; 
    border-right:1px dashed #FFA500 
  }
  .blockChainUses li, .job-card li { flex-basis: 100% }
  .coffee-holder {
    display: block;
    margin: 50px auto 0
  }
  .meetat-coffee code {
    text-align: center;
    margin-top: 0;
    padding-top: 10px;
    display: block;
  }
  .subHead { font-size: 40px }
  .about-us span {
    border: 1px solid #00a1d9;
    display: block;
    margin-bottom: 10px;
    padding: 10px 0
  }
  .about-us span:last-child { border: 1px solid #00a1d9 }
  .start-up {
    margin: 40px 0 0;
    padding: 210px 0 0;
    background-size: 100%
  }
  .service-glimse li { width: 80% }
}
@media (min-width: 768px) { 
  /* .home-banner{ max-height: 299px} */
}

